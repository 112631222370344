import { Control, FormState, UseFormReturn } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { CO_SOFT_CREDIT_CHECK, SOFT_CREDIT_CHECK } from '@genoa/screen-content'
import { Box, Flex, UnorderedList, useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useModal } from '../../../../contexts'
import { useContentOverhaul } from '../../../../hooks'
import { useContent } from '../../../../providers/content'
import { QuestionCircle } from '../../../assets'
import {
  ExtraSmallText,
  Field,
  Headline1,
  InlineButton,
  PrimaryButton,
  SmallText,
  TextInput,
} from '../../../components'
import { MoneyInput } from '../../../components/Input'
import { BasePageLayout } from '../../../layouts'
import { useIncomeCollectionExperiment } from './use-income-collection-experiment'

export type SoftCreditCheckState = {
  dob: string
  ssn: string
  income: string
}

type SoftCreditCheckProps = {
  analyticsScreenName: Analytics.Screens
  onDateChange: (date: string) => void
  onSetSSN: (ssn: string) => void
  onSetIncome: (income: string) => void
  onClickContinue: () => void
  ssnPlaceholder: string
  isLoading: boolean
  onLearnMore: () => void
  onPressWhy: () => void
  control: Control<SoftCreditCheckState>
  formErrors: FormState<SoftCreditCheckState>['errors']
  getValues: UseFormReturn<SoftCreditCheckState>['getValues']
}

export const SoftCreditCheck = (props: SoftCreditCheckProps) => {
  const theme = useTheme()
  const {
    content: { CREDIT_REPORT_INFO_MODAL, INCOME_COLLECTION_MODAL, INFO_COLLECTION_MODAL },
  } = useContent()

  const { isSoftCreditCheckEnabled: contentOverhaulEnabled } = useContentOverhaul()
  const content = contentOverhaulEnabled ? CO_SOFT_CREDIT_CHECK : SOFT_CREDIT_CHECK

  const isIncomeCollectionEnabled = useIncomeCollectionExperiment()
  const modal = useModal()

  const ssn = props.getValues('ssn')
  const date = props.getValues('dob')
  const income = props.getValues('income')
  const baseDisabled = !ssn || !date || props.isLoading

  const creditReportContent = isIncomeCollectionEnabled ? (
    <InlineButton
      textColor={theme.colors.jewelPurple}
      testID="SoftCreditCheckBodyCreditReportLink"
      onClick={openCreditCheckInfoModal}
    >
      <strong>{content.BODY_CREDIT_REPORT_LINK_TEXT}</strong>
    </InlineButton>
  ) : (
    content.BODY_CREDIT_REPORT_LINK_TEXT
  )

  function openCreditCheckInfoModal() {
    modal.show({
      title: CREDIT_REPORT_INFO_MODAL.TITLE,
      render: () => <SmallText>{CREDIT_REPORT_INFO_MODAL.BODY}</SmallText>,
      cta: CREDIT_REPORT_INFO_MODAL.CTA,
    })
  }

  function openIncomeCollectionModal() {
    modal.show({
      title: INCOME_COLLECTION_MODAL.TITLE,
      render: () => (
        <Box textAlign="left" justifyItems="start">
          <SmallText>{INCOME_COLLECTION_MODAL.TOP_BODY}</SmallText>
          <UnorderedList py={5}>
            <SmallText align="left">
              {INCOME_COLLECTION_MODAL.BULLETS.map((v, i) => (
                <li key={i}>{v}</li>
              ))}
            </SmallText>
          </UnorderedList>
          <SmallText>{INCOME_COLLECTION_MODAL.FOOTER_BODY}</SmallText>
        </Box>
      ),
      cta: INCOME_COLLECTION_MODAL.CTA,
    })
  }

  function openInfoCollectionModal() {
    modal.show({
      title: INFO_COLLECTION_MODAL.TITLE,
      render: () => <SmallText>{INFO_COLLECTION_MODAL.BODY}</SmallText>,
      cta: INFO_COLLECTION_MODAL.CTA,
    })
  }

  return (
    <>
      <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
        <Flex direction="column">
          <Box minH="30px" />

          <Headline1>{content.HEADER}</Headline1>

          <Box minH="24px" />

          <SmallText data-testid="SoftCreditCheckBody">
            {content.BODY_1} {creditReportContent} {content.BODY_2}
          </SmallText>
          <Box minH="24px" />
          {isIncomeCollectionEnabled ? (
            <UnorderedList>
              {content.BULLETS.map((v, i) => (
                <SmallText key={i}>
                  <li>{v}</li>
                </SmallText>
              ))}
            </UnorderedList>
          ) : (
            <Box flex={1}>
              <Row>
                <SmallText fontWeight={'bold'}>{content.LEARN_MORE}</SmallText>
                <LearnMoreButton testID="SoftCreditCheckLearnMore" onClick={props.onLearnMore}>
                  <LearnMoreButtonContent>
                    <QuestionCircle />
                  </LearnMoreButtonContent>
                </LearnMoreButton>
              </Row>
            </Box>
          )}
          <Box minH={theme.fixedSizes.lg} />

          <Box>
            <Field
              control={props.control}
              defaultValue={date}
              error={props.formErrors.dob}
              input={TextInput}
              label="Date of birth"
              name="dob"
              onChange={props.onDateChange}
              placeholder="MM / DD / YYYY"
              preventChangeValue={true}
              testID="SoftCreditCheckDateOfBirthInput"
            />
          </Box>

          <Box minH={theme.fixedSizes.md} />

          <Box>
            <Field
              control={props.control}
              error={props.formErrors.ssn}
              input={TextInput}
              label="Social security number"
              name="ssn"
              onChange={props.onSetSSN}
              placeholder={props.ssnPlaceholder}
              preventChangeValue={true}
              testID="SoftCreditCheckSsnInput"
            />
          </Box>
          <Box minH={theme.fixedSizes.md} />

          <Box>
            {isIncomeCollectionEnabled && (
              <Field
                control={props.control}
                error={props.formErrors.income}
                input={MoneyInput}
                label={
                  <>
                    {content.INCOME_LABEL}
                    <LabelQuestionArea as="span">
                      <InlineButton onClick={openIncomeCollectionModal}>
                        <QuestionCircle />
                      </InlineButton>
                    </LabelQuestionArea>
                  </>
                }
                maxLength={8}
                name="income"
                onChange={props.onSetIncome}
                preventChangeValue={true}
                testID="SoftCreditCheckIncomeInput"
              />
            )}
          </Box>

          <Box minH={contentOverhaulEnabled ? theme.fixedSizes.md : '64px'} />

          <FederalLawDisclaimerWrapper>
            <ExtraSmallText>
              {content.FEDERAL_LAW_DISCLAIMER}
              <WhyText
                color={theme.colors.jewelPurple}
                onClick={isIncomeCollectionEnabled ? openInfoCollectionModal : props.onPressWhy}
              >
                {content.LINK_TEXT}
              </WhyText>
            </ExtraSmallText>
          </FederalLawDisclaimerWrapper>

          {contentOverhaulEnabled ? (
            <>
              <Box minH="64px" />
              <ExtraSmallText data-testid="SoftCreditCheckAuth">{content.AUTHORIZATION}</ExtraSmallText>
              <Box minH="16px" />
            </>
          ) : (
            <Box minH="24px" />
          )}

          <PrimaryButton
            disabled={baseDisabled || (isIncomeCollectionEnabled && !income)}
            processing={props.isLoading}
            onClick={props.onClickContinue}
            testID="SoftCreditCheckContinueButton"
          >
            {content.CTA}
          </PrimaryButton>

          <Box minH="16px" />
        </Flex>
      </BasePageLayout>
    </>
  )
}

const LearnMoreButton = styled(Box)`
  display: inline-flex;
  cursor: pointer;
  padding-left: ${(props) => props.theme.fixedSizes.spacing_37_5};
`

const LearnMoreButtonContent = styled(SmallText)`
  color: ${(props) => props.theme.colors.brand['900']};
`
const FederalLawDisclaimerWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.cloud};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
  padding: ${(props) => props.theme.fixedSizes.spacing_50};
  justify-content: center;
  align-items: center;
`

const WhyText = styled(ExtraSmallText)`
  font-weight: bold;
  cursor: pointer;
`

const LabelQuestionArea = styled(Box)`
  margin-left: ${(props) => props.theme.fixedSizes.spacing_25};
`
const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
